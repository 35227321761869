<template>
  <div>
    <b-row style="margin: 6px;">
      <h4>Organizasyon</h4>
    </b-row>
    <b-row style="margin: 10px;">
      <b-form-select v-model="d_organizationSelect.selected_organization" @change="f_selectWdmr307()" style="width: 500px;">
        <option v-for="(org, org_ind) in d_organizationList" :key="org_ind" :value="org">
          {{ org.label  }}  
          <small style="color: blue;">[ {{ org.w_id }} ]</small>
        </option>
      </b-form-select>
    </b-row>
    <b-row style="margin: 6px;">
      <h4>Organizasyon Konusu</h4>
    </b-row>
    <b-row style="margin-left: 10px;">
      <b-form-select v-model="d_organizationSelect.selected_subject" style="width: 500px;">
        <option v-for="(sub, sub_ind) in d_organizationSubjectList" :key="sub_ind" :value="sub">
          {{ sub.subject_name.val }}  
        </option>
      </b-form-select>
    </b-row>
    <b-row style="margin: 6px;">
      <h4>Başlık</h4>
    </b-row>
    <b-row style="margin-left: 10px;">
      <b-form-input v-model="d_organizationSelect.selected_operation_name" size="md" placeholder="Destek Başlığı"style="width: 300px;"></b-form-input>
    </b-row>
    <b-row style="margin: 6px;">
      <h4>Konu</h4>
    </b-row>
    <b-row style="margin: 10px;">
      <b-form-textarea v-model="d_organizationSelect.selected_operation_about" style="width: 730px;" rows="3" max-rows="3" placeholder="Destek Konusunu Belirtin"></b-form-textarea>
    </b-row>
    <b-row style="margin-top: 30px;">
      <b-col cols="9" style="text-align: right;">
        <button type="button" class="btn btn-success" v-on:click="f_newOperationRecord()">kaydet</button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { WmanagerApi } from '@/services/Wmanager/WmanagerApi';
import WdmService from '@/services/wdm';
import {
  default as Modal
} from "@/components/widgets/Modal";
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import OrganizationService from '@/services/organization';
import moment from 'moment';
export default {
  name: 'Support',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
  }),
  components: {
    vSelect,
    Modal
  },
  data () {
    return {
      d_loading: {
        'status': false,
      },
      d_hoverOrganizationIndex: '',
      d_organizationSelect: {
        "selected_organization": null,
        "selected_subject": "",
        "selected_operation_name": "",
        "selected_operation_about": "",
      },
      d_selectedWdmr307: '',
      d_organizationSubjectList: '',
      d_WmanagerLayerData: require('@/services/Wmanager/Layers').Layers,
      d_organization: {
        'id': 'new',
        'name': '',
        'about': '',
        'list': [],
        'tree_detail': { 'label': 'Kullanıcı Bilgileri' },
        'tree_name': { 'label': 'Kullanıcı Listesi' },
        'type': 'organization'
      },
      show_organization_modal: false,
      d_organizationList: [],
      user: {},
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
    // console.log("user,", this.user)
    this.f_getOrganizationList();
  },
  beforeMount () {},
  mounted: function () {},
  methods: {

    f_selectWdmr307: function () {
      console.log("secilen org",this.d_organizationSelect.selected_organization)
      if (this.d_organizationSelect.selected_organization.w_id){
        let wdmr307_id = this.d_organizationSelect.selected_organization.w_id;
        let organization_id = wdmr307_id;
        // if (!this.$route.query === undefined || (this.$route.query !== undefined && (this.$route.query.organization_id === undefined || (this.$route.query.organization_id !== organization_id)))) {
        //   let route_data = {
        //     'query': {
        //       'organization_id': organization_id
        //     }
        //   };
        //   this.$router.push(route_data);
        // }
        let query = 'organization_id=' + organization_id;
        // this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyon verisi getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        OrganizationService.organization_get(query)
          .then(resp => {
            if (resp.data.status_code === '12000') {
              // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
              this.d_selectedWdmr307 = resp.data.organization_data;
              if ("subject_list" in this.d_selectedWdmr307.data.general){
                this.d_organizationSubjectList = this.d_selectedWdmr307.data.general.subject_list.list
                // console.log("this.d_organizationSubjectList:", this.d_organizationSubjectList);
              } else {
                this. d_organizationSubjectList = "";
              }
            } else {
              console.log('error :' + resp.data.message);
            }
          }, resp => {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            console.log(resp);
            let msg = JSON.stringify(resp);
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Organizasyon getirme işlemi hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
          });
      }
    },
    f_newOperationRecord: function () {
      // console.log("operasyon adı: ", this.d_organizationSelect.selected_operation_name)
      // console.log("konu", this.d_organizationSelect.selected_operation_about)
      // console.log("user", this.d_organizationSelect.selected_subject.userid.val)
      // console.log("organization name: ", organization_id)
      if (!this.d_organizationSelect.selected_organization) {
        alert('Lütfen organizasyon seçin.');
      } else if (!this.d_organizationSelect.selected_subject) {
        alert('Lütfen organizasyon konusu seçin.');
      } else if (!this.d_organizationSelect.selected_operation_name) {
        alert('Lütfen başlık girin.');
      } else if (!this.d_organizationSelect.selected_operation_about) {
        alert('Lütfen konu girin.');
      } else {
        let organization_id = this.d_organizationSelect.selected_organization.w_id;
        let query = 'organization_id=' + organization_id;
        let data = {
          'data': {
            "operation_name": this.d_organizationSelect.selected_operation_name,
            "operation_note": this.d_organizationSelect.selected_operation_about,
            "subject_name": this.d_organizationSelect.selected_subject.subject_name.val,
            "priority": "low",
            "task_type": "ticket"
          }
        };
        OrganizationService.operation_new(query, data)
         .then(resp => {
          if (resp.data.status_code === '12000') {
            // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
            let msg = 'İşlem tamamlandı.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
            this.$store.commit('MutationModal', modal_data);
            this.$store.commit('MutationModalShow', true);
            this.f_goToThisTicket();
          } else {
            console.log('error :' + resp.data.message);
          }
        }, resp => {
          // this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          console.log(resp);
          let msg = JSON.stringify(resp);
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'işlem hakkında' };
          this.$store.commit('MutationModal', modal_data);
          this.$store.commit('MutationModalShow', true);
        });
      }
    },
    f_getOrganizationList: function () {
      this.$store.commit('MutationLoading', { 'status': true, 'data': { 'label': 'Organizasyonlar getirme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      OrganizationService.organization_list()
        .then(resp => {
          this.$store.commit('MutationLoading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '12000') {
            this.d_organizationList = resp.data.list;
          } else {
            alert('error ', resp.data.status_code + ' ' + resp.data.status_message);
          }
        });
    },
    f_goToThisTicket: function () {
      this.$router.push({ path: '/activeticketlist' });
    },
  },
  watch: {}
};

</script>

<style>
.organization_list_item: {
  height: 100%;
  cursor: pointer;
}


/*.organization_list_item:hover {
  height: 100%;
  background: #85FFBD;
}
*/

</style>


